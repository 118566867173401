<template>
  <div class=" header-margin text-light">
    <b-container fluid class="home-container title-container">
      <b-row class="text-center py-5">
        <b-col cols="12" class="title pt-5 banner-title">
          <img src="../../assets/trackd-icon-white.svg" class="trackd-icon" />
          <h1 class="heading pt-4 pb-5 mt-4 mt-sm-0">Features & Pricing</h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-sm-4 px-md-5 px-lg-4 px-xl-5 content-spacing">
      <div class="pt-5 pb-4">
        <b-row class="">
          <b-col cols="12" class=" ">
            <h3 class="text-center section-heading pb-3">
              Take Back Control of your Money
            </h3>

            <p class="text-center section-desc">
              Whether you're a serious Artist or music is your hobby, we want to
              support you and help you earn from your creativity.
              <br class="d-md-block" />Streaming platforms offer listeners a
              world of music at their fingertips but are notorious for super low
              Artist payments and subsequently,<br class="d-none" />
              they don’t support the Artist fairly. <br /><br />
              As an Artist earning typically just $0.004 per stream you need to
              generate millions of monthly streams,<br class="d-none" />
              just to earn a minimum wage from streaming alone.<br />
              <span class="font-weight-bold">That’s just not fair.</span>
              <br /><br />
              With Trackd, Artists are able to receive an income securely and
              directly from Fans through either<br />
              ChipIn or ChipIn+ Subscriptions. So whats the difference?
            </p>
          </b-col>
        </b-row>
      </div>

      <div id="chipin-compare" class="pt-0 pb-4 pb-sm-5">
        <b-row>
          <b-col class="text-right ">
            <img src="../../assets/chipin.svg" class="mb-4 chipin-logo" />
            <p class="font-weight-bold mb-0">
              ChipIn, A virtual ‘busker's’ tip hat
            </p>
            <p class="font-weight-light">
              Fans can make a one time, or monthly recurring donation directly
              to the Artist as appreciation for your Art.
            </p>
            <p class="font-weight-light">
              With ChipIn the Artist receives 95% of the Fans donations direct
              from our payment processor.
            </p>
            <p class="font-weight-light">You can’t lock content with ChipIn</p>
          </b-col>
          <b-col cols="1" class="text-center pt-5">
            <span class="v" style="">V</span>
          </b-col>
          <b-col class="text-left">
            <img
              src="../../assets/chipin-plus-text.svg"
              class="mb-4 chipin-logo"
            />
            <p class="font-weight-bold mb-0">ChipIn+, SuperFan Access</p>
            <p class="font-weight-light">
              As an Artist you can lock, manage and share exclusive songs and
              choose your monthly subscription price for access to your locked
              content.
            </p>
            <p class="font-weight-light">
              Fans subscribe to the Artist directly and support them in creating
              amazing music for a small monthly contribution. With ChipIn+ the
              Artist receives 85% of the Fans monthly subscription revenues.
            </p>
          </b-col>
        </b-row>

        <b-row class="mb-0 mb-sm-5 pt-5 pb-3 pb-sm-5">
          <b-col cols="10" sm="12" class="section-desc text-center pt-3 px-0 offset-1 offset-sm-0">
            At Trackd we like to keep things simple. No hidden charges or
            extras.<br />
            <br />
            In exchange for choosing Trackd we will Support you with an Artist
            Relations team, an exclusive community and inclusive marketing of
            your music and profile within Trackd’s App and Web properties.
          </b-col>
        </b-row>
      </div>
    </b-container>

    <div id="">
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="0" md="0" lg="1" class="d-none"></b-col>
          <b-col
            cols="12"
            md="12"
            lg="5"
            xl="4"
            order="2"
            order-md="2"
            order-lg="1"
            order-xl="1"
            class="pt-4"
          >
            <BackStage></BackStage>
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
            order="1"
            order-md="1"
            order-lg="2"
            order-xl="2"
          >
            <AllAreas></AllAreas>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <AccessAllAreasModal></AccessAllAreasModal>
    <BackStageModal></BackStageModal>
  </div>
</template>

<script>
import AccessAllAreasModal from "../../components/modal/pricing/AccessAllAreas.vue";
import BackStageModal from "../../components/modal/pricing/BackStage.vue";
import AllAreas from "../../components/pricing/AllAreas.vue";
import BackStage from "../../components/pricing/BackStage.vue";

export default {
  name: "Pricing",
  components: {
    AccessAllAreasModal,
    BackStageModal,
    BackStage,
    AllAreas,
  },
  methods: {},
};
</script>

<style scoped>
.c-pricing-passes {
  justify-content: space-between;
  position: relative;
}

.c-middle-pricing-pass {
  position: absolute;
  left: 30vw;
  top: -53px;
  z-index: 1000;
}

.c-chipin-comparison {
  display: flex;
  justify-content: space-between;
}

.home-container {
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, black 95%),
    url("../../assets/pricing_page/background.jpg");
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 800px;
  /* Center and scale the image nicely */
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.banner-title {
  margin-top: 12%;
}

.content-spacing {
  position: relative;
  top: -100px;
}

#pricing {
  padding-bottom: 250px;
  padding-left: 20px;
  padding-right: 30px;
}

.c-middle-pricing-pass #pass-container {
  width: 37vw;
}

.heading {
  font-size: 48px;
  font-weight: 300;
  line-height: 36px;
}

@media (min-width: 576px) {
}

@media (min-width: 576px) {
}

.title-container {
  position: relative;
}

.heading {
  font-size: 48px;
  font-weight: 300;
  line-height: 36px;
}

.trackd-icon {
  height: 40px;
}

.section-heading {
  font-size: 40px;
  font-weight: 300;
  line-height: 90px;
}

.section-desc {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.chipin-logo {
  height: 33px;
}

.chipin-plus-logo {
  height: 33px;
}

.v {
  font-size: 60px;
  font-weight: 200;
}

@media (max-width: 1200px) {
  .c-v {
    padding-bottom: 141px;
  }

  .title-container {
    position: relative;
  }

  .heading {
    font-size: 48px;
    font-weight: 300;
    line-height: 36px;
  }

  .trackd-icon {
    height: 40px;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .section-desc {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .chipin-logo {
    height: 33px;
  }

  .chipin-plus-logo {
    height: 33px;
  }

  .v {
    font-size: 60px;
    font-weight: 200;
  }

  #pass-container {
    width: 34vw;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .v {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 36px;
  }

  .content-spacing {
    position: relative;
    top: -200px;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 50px;
  }

  .section-desc {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }

  #chipin-compare {
    font-size: 14px;
  }
  
}

@media (max-width: 375px) {
  .v {
    display: none;
  }
}

@media (max-width: 320px) {
  .v {
    display: none;
  }
}
</style>
