<template>
  <div>

    <div class="gradient"></div>

    <div class="pass-bg text-center mb-5 px-3 back-stage">
      <img
        src="../../assets/pass-clip.png"
        class="pass-clip mx-auto"
        style="z-index: 99;"
        alt=""
      />
      <div class="pass-eyelet"></div>
      <h4 class="mt-3 pt-2 pass-positioning title">Back Stage Pass</h4>
      <p class="sub-title pass-positioning mb-2">Upload or Record, Share & Make Money</p>
      <p class="price pass-positioning mb-0">$3.99<span class="price-text">/mo</span></p>
      <p class="price-text pass-positioning">Cancel Anytime.</p>


      
      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
        </b-col>
        <b-col class="pl-0">
          <p class="heading text-left mb-1">Creator Tools</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="17" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Activate <span class="font-weight-normal">ChipIn</span> and receive donations from fans</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="17" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Access to dedicated Artist Support and an exclusive community to help grow you as an Artists</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="17" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Unlock 60+ in-app iOS Studio creator tools</p>
        </b-col>
      </b-row>

      

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
        </b-col>
        <b-col class="pl-0">
          <p class="heading text-left mb-1">Discovery for Fans</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="17" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Ad-Free Listening to thousands of Artists worldwide on web and mobile app</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col class="text-center">
          <b-button variant="link" v-b-modal.pricing-backstage-modal class="text-yellow text-center mx-auto pl-0 ml-0">View All Features</b-button>
        </b-col>
      </b-row>
      

      <b-row class="pass-positioning">
        <b-col class="text-center">
          <b-button v-if="isAuthenticated && whatAccountType() !== 'access all areas' && isAuthenticated && whatAccountType() !== 'supporter' && isAuthenticated && whatAccountType() !== 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" @click="BackStagePlanPlan()"> Select </b-button>
          <b-button v-else-if="isAuthenticated && whatAccountType() === 'access all areas' || isAuthenticated && whatAccountType() === 'supporter' || isAuthenticated && whatAccountType() === 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" :to="{name: 'AccountSettings'}"> Select </b-button>
          <b-button v-else-if="!isAuthenticated" class="btn-rounded px-5 py-2 choice-btn" variant="info" v-b-modal.signup-modal> Select </b-button>
       </b-col>
      </b-row>

    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
        stripe: self.stripe = Stripe(process.env.VUE_APP_STRIPE_TCLLC_KEY), 
    }
  },
  methods: {
    ...mapGetters(["whatAccountType"]),
    ...mapActions("Upgrade", ["subscribe"]),
    ...mapMutations(["kochavaEvent"]),
    BackStagePlanPlan() {
        
        this.kochavaEvent("Checkout Start", {
          package: 'Back Stage Pass'
        });

        var self = this;

        this.subscribe({price_id: process.env.VUE_APP_STRIPE_BACK_STAGE_PRICE_ID})
        .then(res => {

            return self.stripe.redirectToCheckout({ 
                sessionId: res.checkout_session_id,
            })

        })
        .catch(error => {
          error
            this.name = 'Sorry something went wrong!';
            
        });

    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
    ])
  }
};
</script>

<style scoped>

.gradient {
  position: relative; 
  z-index: 100;
  margin-top: -210px; 
  padding-bottom: 200px;

  background: linear-gradient(180deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0.9) 50%, rgba(0,0,0,0.2) 75%,  rgba(0,0,0,0) 95%);
}

.back-stage {
  position: relative;
  top: 20px;
}

.title {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
}

.sub-title {
font-size: 16px;
  line-height: 21px;
}

.price {
  font-size: 61px;
  line-height: 71px;
  font-weight: 900;
  color: #fff;
}

.price-text {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.heading {
  font-size: 18px;
  line-height: 24px;
}

.font-weight-500 {
  font-weight: 400;
}

.item-text {
  font-weight: 200;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.152972px;
  text-align: left;
  padding-right: 30px;
}

.tick {
  position: relative;
  top: -2px;
}

.text-yellow {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.152972px;
  color: #fff;
}

.choice-btn {
  border-radius: 10px;
  position: relative;
  top: 15px;
}

.pass-bg {
  border-radius: 30px;
  background: #000;
  -webkit-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  min-height: 200px;
  padding-top: 15px;
  height: 611px;
  position: relative;
  right: -100px;
  margin-right: 50px;
  width: 360px;
}

.pass-eyelet {
  border-radius: 20px;
  background: #131212;
  -webkit-box-shadow: inset 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  box-shadow: inset 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  height: 20px;
  width: 90px;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;
  top: -244px;
}

.pass-clip {
  margin: 0 auto;
  position: relative;
  top: -209px;
}

.pass-positioning {
  position: relative;
  top: -239px;
}


@media (max-width: 1200px) {

}

@media (max-width: 992px) {

.pass-bg {
  width: auto;
  right: 0;
  margin-right: 0;
  margin-top: 200px;
}

.gradient {
  transform: translateY(90%);
}


}

@media (max-width: 768px) {

.pass-bg {
  width: auto;
  right: 0;
  margin-right: 0;
  margin-top: 200px;
}

.item-text {
  padding-right: 0px;
}

}

@media (max-width: 375px) {

.pass-bg {
  width: auto;
  min-height: 650px;
  right: 0;
  margin-right: 0;
  margin-top: 230px;
}
.item-text {
  padding-right: 0px;
}


}

@media (max-width: 320px) {

.pass-bg {
  width: auto;
  min-height: 675px;
}

.item-text {
  padding-right: 0px;
}

}
</style>
