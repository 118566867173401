<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="pricing-backstage-modal"
      ref="pricing-backstage-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-class="pb-0"
      body-class="pt-0"
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-container fluid>
        <b-row>
          <b-col class="text-center">
            <h2 class="pricing-modal-title">Back Stage Pass</h2>
            <h4 class="pricing-modal-title-text">Full Feature List</h4>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 mt-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3">
              <b-col class="included-info pl-1" cols="2">
                
              </b-col>
              <b-col class="pl-0 pr-3">
                <p class="heading-text mb-0">Creator Tools</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Create your Artist profile in-app and on web
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Activate ChipIn to accept one time or recurring donations without content locking. All monies go directly to the Artist, the only deduction being a transaction fee of 5%. 
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Access to dedicated Artist Support and an exclusive community to help grow you as an Artists
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" class="align-top" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Access the Studio within the iOS app with over 30+ features including:
                  <ul>
                    <li>Global Collaboration | Edit (Trim / Copy / Paste) | 8 Track Studio & Mixer | Bounce Down & Merge Tracks | Levels | Reverb | Audio Copy & Paste | Import and Export M4a / WAV Pro Audio | Line Input | Re-Track | Direct Upload from other platforms</li>
                    <li>Lyric Tools</li>
                    <li>Library & Playlists</li>
                    <li>Custom VirtualVinyl Social Sharing</li>
                    <li>Direct & Group Messaging</li>
                    <li>Advanced Artist Analytics</li>
                    <li>Unlimited SoundSafe Cloud Backup</li>
                  </ul>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Use both ChipIn & ChipIn+ revenue generation options
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3">
              <b-col class="included-info pl-1" cols="2">
                
              </b-col>
              <b-col class="pl-0 pr-3">
                <p class="heading-text mb-0">Discover Features</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Ad Free Listening
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Direct Fan to Artist Messaging
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  JumpIn and Collaborate In-App
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 feature-text">
            <b-row class="pl-3 mb-2">
              <b-col class="included-info pl-1" cols="2">
                <img src="../../../assets/tick.svg" alt="" class="align-top" />
              </b-col>
              <b-col class="pl-0 pr-3">
                <p
                  class="font-weight-light mb-1 subscription pl-0"
                >
                  Fully Featured Player including 
                  <ul>
                    <li>Artist & Song Insights</li>
                    <li>Lyrics</li>
                    <li>Back Cover Art</li>
                    <li>Airplay & Chromecast Support</li>
                    <li>Libraries, Curated Playlists & Featured Artists</li>
                  </ul>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2 pl-3 pr-0">
          <b-col cols="12" class="px-5 text-center">
          <b-button v-if="isAuthenticated && whatAccountType() !== 'access all areas' && isAuthenticated && whatAccountType() !== 'supporter' && isAuthenticated && whatAccountType() !== 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" @click="BackStagePlanPlan()"> Select </b-button>
          <b-button v-else-if="isAuthenticated && whatAccountType() === 'access all areas' || isAuthenticated && whatAccountType() === 'supporter' || isAuthenticated && whatAccountType() === 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" :to="{name: 'AccountSettings'}"> Select </b-button>
          <b-button v-else-if="!isAuthenticated" class="btn-rounded px-5 py-2 choice-btn" variant="info" v-b-modal.signup-modal> Select </b-button>
          </b-col>
        </b-row>

      </b-container>
    </b-modal>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PricingStarter",
  data() {
    return {
      chipin_slide: false,
      stripe: self.stripe = Stripe(process.env.VUE_APP_STRIPE_TCLLC_KEY), 
      pages: [],
    };
  },
  methods: {
    ...mapGetters(["whatAccountType"]),
    ...mapActions("Upgrade", ["subscribe"]),
    ...mapMutations(["kochavaEvent"]),
    BackStagePlanPlan() {

        this.kochavaEvent("Checkout Start", {
          package: 'Back Stage Pass'
        });
        
        this.$bvModal.hide('pricing-backstage-modal')    
        
        var self = this;

        this.subscribe({price_id: process.env.VUE_APP_STRIPE_BACK_STAGE_PRICE_ID})
        .then(res => {

            return self.stripe.redirectToCheckout({ 
                sessionId: res.checkout_session_id,
            })

        })
        .catch(error => {
          error
            this.name = 'Sorry something went wrong!';
            
        });

    }
  },
  computed: {
    ...mapGetters(["user_image", 'isAuthenticated']),
  },
  mounted: function() {},
};
</script>

<style scoped>

.modal {
  background: rgba(0,0,0,0.85)
}
.pricing-modal-title {
  font-weight: 900;
  font-size: 31px;
  line-height: 36px;
  margin-bottom: 0px;
}

.pricing-modal-title-text {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.heading-text {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

.subscription {
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.152972px;
}

.btn-rounded {
  border-radius: 10px;
}

</style>
