<template>
  <div>

    <div class="gradient"></div>

    <div class="pass-bg text-center mb-5 px-3 access-all-areas-pass">
      <img 
        src="../../assets/pricing_page/bling.svg" class="top-bling" alt="">
      <img 
        src="../../assets/pricing_page/bling.svg" class="bottom-bling" alt="">
      <img
        src="../../assets/pass-clip.png"
        class="pass-clip mx-auto"
        style="z-index: 99;"
        alt=""
      />
      <div class="pass-eyelet"></div>
      <h4 class="mt-3 pt-2 pass-positioning title">Access All Areas</h4>
      <p class="sub-title pass-positioning mb-2">The Pro Choice inc. <img src="../../assets/chipin-plus-text.svg" height="24" class="chipin-plus ml-1"/> </p>
      <p class="price pass-positioning mb-0">$4.49<span class="price-text">/mo</span></p>
      <p class="price-text pass-positioning">plus 15% of the monthly income <br> from ChipIn+. Cancel Anytime.</p>

      
      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
        </b-col>
        <b-col class="pl-0">
          <p class="heading text-left mb-1">Creator Tools</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="23" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Activate <span class="font-weight-normal">ChipIn<span class="trackd-blue">+</span> to lock content</span> via monthly subscriptions. Retain 85% of all subscription revenues</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="23" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text font-weight-500">All the web and app tools included Back Stage Pass package</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="23" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Access to dedicated Artist Support team and an exclusive online community to help you grow as an Artist on Trackd</p>
        </b-col>
      </b-row>


      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
        </b-col>
        <b-col class="pl-0">
          <p class="heading text-left mb-1">Discovery for Fans</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col cols="2" class="text-center px-0">
          <img src="../../assets/tick.svg" height="23" alt="" class="mx-auto tick" />
        </b-col>
        <b-col class="pl-0">
          <p class="item-text">Ad-Free Listening to thousands of Artists worldwide on web and mobile app</p>
        </b-col>
      </b-row>

      <b-row class=" pass-positioning">
        <b-col class="text-center">
          <b-button variant="link" v-b-modal.pricing-allareas-modal class="text-yellow text-center mx-auto pl-0 ml-0">View All Features</b-button>
        </b-col>
      </b-row>
      

      <b-row class="pass-positioning">
        <b-col class="text-center">
          <b-button v-if="isAuthenticated && whatAccountType() !== 'access all areas' && isAuthenticated && whatAccountType() !== 'supporter' && isAuthenticated && whatAccountType() !== 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" @click="AccessAllAreasPlan()"> Select </b-button>
          <b-button v-else-if="isAuthenticated && whatAccountType() === 'access all areas' || isAuthenticated && whatAccountType() === 'supporter' || isAuthenticated && whatAccountType() === 'back stage pass'" class="btn-rounded px-5 py-2 choice-btn" variant="info" :to="{name: 'AccountSettings'}"> Select </b-button>
          <b-button v-else-if="!isAuthenticated" class="btn-rounded px-5 py-2 choice-btn" variant="info" v-b-modal.signup-modal> Select </b-button>
       </b-col>
      </b-row>

    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
        stripe: self.stripe = Stripe(process.env.VUE_APP_STRIPE_TCLLC_KEY), 
    }
  },
  methods: {
    ...mapGetters(["whatAccountType"]),
    ...mapActions("Upgrade", ["subscribe"]),
    ...mapMutations(["kochavaEvent"]),
    AccessAllAreasPlan() {
        
        this.kochavaEvent("Checkout Start", {
          package: 'Access All Areas'
        });

        var self = this;

        this.subscribe({price_id: process.env.VUE_APP_STRIPE_ACCESS_ALL_AREAS_PRICE_ID})
        .then(res => {

            return self.stripe.redirectToCheckout({ 
                sessionId: res.checkout_session_id,
            })

        })
        .catch(error => {
          error
            this.name = 'Sorry something went wrong!';
            
        });

    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
    ])
  }
};
</script>

<style scoped>

.gradient {
  position: relative; 
  z-index: 100;
  margin-top: -210px; 
  padding-bottom: 200px;

  background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.7) 40%, rgba(0,0,0,0.2) 75%,  rgba(0,0,0,0) 95%);
}

.access-all-areas {
  position: relative;
  top: -30px;
  z-index: 700;

}

.top-bling {
  position: absolute;
  top: -30px;
  right: -18px;
}

.bottom-bling {
  position: absolute;
  bottom: 30px;
  right: -4px;
  height: 50px;
}

.title {
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
}

.sub-title {
  font-size: 18px;
  line-height: 21px;
}

.price {
  font-size: 61px;
  font-weight: 900;
  line-height: 71px;
  color: #F2B471;
}

.price-text {
  color: #F2B471;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.heading {
  font-size: 18px;
  line-height: 24px;
}

.item-text {
  font-weight: 200;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.152972px;
  text-align: left;
}

.tick {
  position: relative;
  top: -2px;
}

.text-yellow {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.152972px;
  color: #F2B471;
}

.choice-btn {
  border-radius: 10px;
  position: relative;
  top: 15px;
}

.trackd-blue {
  color: #00AFDC;
}

.font-weight-500 {
  font-weight: 400;
}

.pass-bg {
  border-radius: 30px;
  background: #000;
  -webkit-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  min-height: 200px;
  padding-top: 15px;
  height: 646px;
  margin-left: 50px;
}

.pass-eyelet {
  border-radius: 20px;
  background: #131212;
  -webkit-box-shadow: inset 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  box-shadow: inset 0px 0px 15px 4px rgba(255, 255, 255, 0.35);
  height: 20px;
  width: 90px;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;
  top: -244px;
}

.pass-clip {
  margin: 0 auto;
  position: relative;
  top: -209px;
}

.pass-positioning {
  position: relative;
  top: -239px;
}


@media (max-width: 1200px) {
}


@media (max-width: 768px) {

  .pass-bg {
    margin-left: 0px;
    margin-left: 15px;
    margin-right: 15px;
    height: 677px;
  }

  .top-bling {
    right: 10px;;
  }

  .bottom-bling {
    right: 10px;
  }
}

@media (max-width: 375px) {

.pass-bg {
  width: auto;
  min-height: 737px;
}


}

@media (max-width: 320px) {

.pass-bg {
  width: auto;
  min-height: 810px;
}


}


</style>
